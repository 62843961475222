export const inputPropsStyle = {
  color: "#FFFFFF", //placeholder
  background: "rgba(0, 0, 0, 0.24)",
  padding: "16px",
  Height: "128px",
  minHeight: "128px",
  alignItems: "start",
  fontStyle: "normal",
  backdropFilter: "blur(10px)",
};

export const inputPropsDisableStyle = {
  color: "#2B303B", //placeholder
  padding: "16px",
  minHeight: "128px",
  alignItems: "start",
  fontStyle: "normal",
};

export const inputPropsErrorStyle = {
  color: "#FFF", //placeholder
  background: "#0E1013",
  padding: "16px",
  Height: "128px",
  minHeight: "128px",
  maxHeight: "360px",
  alignItems: "start",
  fontStyle: "normal",
};

export const countTextStyle = {
  marginTop: "8px",
  color: "#AEB4C1",
  padding: "0px",
  textAlign: "end",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
  justifySelf: "right",
};
export const countTextBoxStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
};
