import { ThemeProvider } from "@mui/material/styles";

import { createTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import VConsole from "vconsole";
import { CustomQueryClientProvider } from "./utils/helper";
import { LayoutProvider } from "./context/LayoutContext";

const Theme = createTheme({
  typography: {
    fontFamily: "Poppins-Regular",
  },
});

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_TOGGLE_VCONSOLE === "true") {
      new VConsole();
    }
  }, []);

  window.onbeforeunload = () => {
    localStorage.clear();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden auto";
    document.title = "Survey";
  }, []);

  return (
    <BrowserRouter>
      <CustomQueryClientProvider>
        <ThemeProvider theme={Theme}>
          <LayoutProvider>
            <Routes />
            <ReactQueryDevtools initialIsOpen={false} />
          </LayoutProvider>
        </ThemeProvider>
      </CustomQueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
