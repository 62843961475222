import { Typography, Container } from "@mui/material";
import styled from "styled-components";

export const containerWidth = {
  maxWidth: "690px",
};
export const smMediaQuery = "@media (max-width: 375px)";
export const mdMediaQuery = "@media (min-width: 376px)";
export const lgMediaQuery = "@media (min-width: 835px)";

export const containerStyle = {
  marginTop: "64px",
  width: "690px",
  [smMediaQuery]: {
    width: "100vw",
    maxWidth: "375px",
    minWidth: "240px",
    paddingX: "24px",
  },
  [mdMediaQuery]: {
    width: "100vw",
    maxWidth: "834px",
    paddingX: "24px",
  },
  [lgMediaQuery]: {
    width: "690px",
    maxWidth: containerWidth,
    paddingX: "24px",
  },
};

export const containerDescStyle = {
  width: "690px",
  [smMediaQuery]: {
    width: "100vw",
    maxWidth: "375px",
    minWidth: "240px",
    paddingX: "24px",
  },
  [mdMediaQuery]: {
    width: "100vw",
    maxWidth: "834px",
    paddingX: "24px",
  },
  [lgMediaQuery]: {
    width: "690px",
    maxWidth: containerWidth,
    paddingX: "24px",
  },
};

export const containerButtonStyle = {
  marginY: "64px",
  display: "flex",
  width: containerWidth,
  justifyContent: "end",
  [smMediaQuery]: {
    width: "100vw",
    maxWidth: "375px",
    minWidth: "240px",
    paddingX: "24px",
  },
  [mdMediaQuery]: {
    width: "100vw",
    maxWidth: "834px",
    paddingX: "24px",
  },
  [lgMediaQuery]: {
    width: "690px",
    maxWidth: containerWidth,
    paddingX: "24px",
  },
};

export const OptionalQuestionStyle = styled.span`
  color: #6e7a91;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const inputLabelStyle = {
  marginTop: "20px",
  color: "#AEB4C1",
};

export const inputLabelDisableStyle = {
  marginTop: "20px",
  color: "#2B303B",
};
export const inputLabelErrorStyle = {
  marginTop: "20px",
  color: "#fff",
};

export const inputBaseStyle = {
  marginTop: "20px",
  borderRadius: "16px",
  backgroundColor: "rgba(0, 0, 0, 0.24)",
  color: "#fff",
  backdropFilter: "blur(10px)",
};

export const inputBaseDisableStyle = {
  marginTop: "20px",
  borderRadius: "16px",
  color: "#2B303B",
  background: "#1A1D23",
};

export const inputBaseErrorStyle = {
  marginTop: "20px",
  borderRadius: "16px",
  color: "#fff",
  background: "#0E1013",
};

export const QuestionTitleText = styled(Typography)`
  &.MuiTypography-root {
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const QuestionDescriptionText = styled(Typography)`
  &.MuiTypography-root {
    color: #959eaf;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
  }
`;

export const outlineInputStyle = {
  "& fieldset": {
    borderColor: "#393F4B",
  },
  "&:hover fieldset": {
    borderColor: "#8A94A8",
    color: "#AEB4C1",
  },
  "&.Mui-focused fieldset": {
    color: "#AEB4C1",
    border: "1px solid #414958",
  },
};

export const outlineInputDisableStyle = {
  "& fieldset": {
    borderColor: "#414958",
  },
  "&:hover fieldset": {
    borderColor: "#414958",
  },
  "&.Mui-focused fieldset": {
    border: "1px solid #414958",
  },
};
export const outlineInputErrorStyle = {
  "& fieldset": {
    borderColor: "#FF2C2C",
  },
  "&:hover fieldset": {
    borderColor: "#FF2C2C",
  },
  "&.Mui-focused fieldset": {
    border: "1px solid #FF2C2C",
  },
};

export const inputLabelFocusStyle = {
  color: "#AEB4C1",
};
export const inputLabelFocusErrorStyle = {
  color: "#fff",
};

export const InlineRequireText = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 8px;
    color: #ff2c2c;
    font-family: "Poppins-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const DangerousDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  color: #aeb4c1;
  margin-top: 8px;
`;

export const DangerousDivTiTle = styled.div`
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;
