import { Radio, SxProps, Theme } from "@mui/material";
import { inputLabelStyle, outlineInputStyle } from "../common/Style";
import styled from "styled-components";

export const radioGroupStyle = { gap: "16px" };
export const radioLabelStyle = (
  selectedOption: number,
  itemValue: number
): SxProps<Theme> => ({
  height: "64px",
  paddingLeft: "8px",
  marginLeft: "0px",
  marginRight: "0px",
  border: `1px solid ${selectedOption === itemValue ? "#1AE5BC" : "#393F4B"}`,
  borderRadius: "16px",
  color: selectedOption === itemValue ? "#1AE5BC" : "#FFFFFF",
  backgroundColor: selectedOption === itemValue ? "#1AE5BC14" : undefined,
});

export const radioLabelOtherStyle = (
  selectedOption: string,
  itemValue: string
): SxProps<Theme> => ({
  height: "136px",
  paddingX: "8px",
  border: `1px solid ${selectedOption === itemValue ? "#1AE5BC" : "#393F4B"}`,
  borderRadius: "16px",
  color: selectedOption === itemValue ? "#1AE5BC" : "#FFFFFF",
  backgroundColor: selectedOption === itemValue ? "#1AE5BC14" : undefined,
  display: "flex",
  "& .MuiFormControlLabel-root span": {
    boxSizing: "border-box",
    width: "90%",
  },
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
  margin: 0,
});

export const RadioAster = styled(Radio)`
  &.MuiButtonBase-root {
    color: #fff;
    &.Mui-checked {
      color: #1ae5bc;
    }
  }
`;

export const RadioAsterOther = styled(Radio)`
  &.MuiButtonBase-root {
    color: #fff;
    &.Mui-checked {
      color: #1ae5bc;
    }
  }
  &.MuiRadio-root {
    align-self: start;
    margin-top: 8px;
  }
`;

export const radioOtherTextFieldStyle = {
  width: "570px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: " 4px",
  marginY: "5px",
  marginX: "0px",
  border: "none",
  borderRadius: "10px",
  "& input::placeholder": {
    color: "#FFFFFF",
  },
  "& .MuiInputBase-root": {
    color: "#FFFFFF",
    borderRadius: "16px",
    background: " rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(10px)",
    marginY: "5px",
    marginX: "0px",
  },
  "& .MuiOutlinedInput-root": outlineInputStyle,
  "& .MuiInputLabel-root.Mui-focused": inputLabelStyle,
};


