import { Route, Routes as RoutesLib } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Finish from "../pages/Finish";
import Home from "../pages/Home";
import Error from "../pages/Error";
import Retry from "../pages/Retry";

import * as paths from "../constants/paths";

const Routes = () => {
  return (
    <RoutesLib>
      <Route
        element={<MainLayout component={Home} />}
        path={paths.home()}
        errorElement={paths.error()}
      />
      <Route
        element={<MainLayout component={Finish} />}
        path={paths.finish()}
      />
      <Route element={<MainLayout component={Error} />} path={paths.error()} />
      <Route element={<MainLayout component={Retry} />} path={paths.retry()} />
    </RoutesLib>
  );
};

export default Routes;
