import styled from "styled-components";
import { GRAY_F9FAFB } from "../../../../constants/colors";
import { Button } from "@mui/material";

export const Container = styled.div`
  background-color: ${GRAY_F9FAFB};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  // padding-top: 80px;
  text-align: center;
  // height: 100vh;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 160px;
  max-height: 160px;
  object-fit: fit;
  align-self: center;
`;

export const ContainerTitle = styled.div`
  margin: 24px 16px 0px;
`;

export const ContainerDetail = styled.div`
  margin-top: 8px;
`;

export const ContainerErrorCode = styled.div`
  margin-top: 8px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

export const StyledButton = styled(Button)`
  margin-top: 24px !important;
  display: flex;
  width: 320px;
  height: 50px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px !important;
  background-color: #34d1ad !important;
`;
