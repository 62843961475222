import DropDownCard from "../../../../components/Dropdown";
import RatingScore from "../../../../components/RatingScore";
import TextAreaQuestion from "../../../../components/TextArea";
import TextFieldQuestion from "../../../../components/TextField";
import CheckBoxQuestion from "../../../../components/Checkbox";
import { Box, Container } from "@mui/material";
import RadioQuestion from "../../../../components/RadioWithOther";
import { useLayout } from "../../../../context/LayoutContext";
import {
  DangerousDiv,
  containerButtonStyle,
  containerDescStyle,
  containerWidth,
} from "../../../../components/common/Style/index";
import { SubmitHandler, useForm } from "react-hook-form";
import Title from "../../../../components/common/Title";
import { useEffect, useMemo, useState } from "react";
import {
  clearLocal,
  getFieldLang,
  getLang,
  resetToken,
  useRouter,
} from "../../../../utils/helper";
import { useSubmitAnswer } from "../../../../services/home/home-query";
import { useNavigate } from "react-router-dom";
import { OnepageProps } from "./type";
import { SubmitAnswerBody } from "../../../../services/home/home-type";
import CommonPrimaryButton from "../../../../components/common/CommonPrimaryButton";
import Loading from "../../../../components/common/Loading";
import ProgressBar from "../../../../components/ProgressBar";
import { countTotalMandatoryQuestions } from "../../../../utils/question-helper";

const OnePage = ({ data }: OnepageProps) => {
  const { answerCount, setAnswerCount, isTabletSize } = useLayout();
  const { data: resAnswers, mutate: submitAnswer } = useSubmitAnswer();
  const navigate = useNavigate();
  const [checkError, setCheckError] = useState(false);
  const { query } = useRouter();
  const {
    setValue,
    handleSubmit,
    register,
    control,
    resetField,
    getValues,
    formState: { errors },
    setFocus,
    trigger,
  } = useForm<Answer[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const lang = useMemo(() => {
    return getLang() || "en";
  }, []);

  useMemo(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const errArray = Object.entries(errors);
      for (const err of errArray) {
        const elements = document.querySelectorAll(`[name="${err[0]}"]`);
        if (elements.length > 0) {
          elements[0].scrollIntoView?.({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
        break;
      }
    }, 50);
  }, [errors, checkError]);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data, setIsLoading]);

  const onSubmit: SubmitHandler<Answer[]> = (submittedData) => {
    setButtonLoading(true);
    const result = Object.entries(submittedData).map(([key, value]) => {
      if (!value) {
        return {
          q_id: +key + 1 || null,
          opt_id: null,
          opt_ids: null,
          opt_short_text: null,
          opt_long_text: null,
          opt_others: null,
        };
      }
      return {
        q_id: +key + 1 || null,
        opt_id: Number(value.opt_id) || null,
        opt_ids: value.opt_ids?.sort() || null,
        opt_short_text: value.opt_short_text || null,
        opt_long_text: value.opt_long_text?.trimEnd() || null,
        opt_others: value.opt_others || null,
      };
    });
    const payload = {
      survey_group_id: Number(query?.survey_group_id) || 0,
      channel: "aster",
      lang: lang,
      consents: [
        {
          consent_id: 1,
          allowed: true,
        },
      ],
      answers: result,
    } as SubmitAnswerBody;
    submitAnswer(payload);
  };
  useEffect(() => {
    if (resAnswers && resAnswers.code === 0) {
      clearLocal();
      resetToken();
      navigate("/finish");
    }
  }, [resAnswers, submitAnswer]);

  useEffect(() => {
    setTimeout(function () {
      setButtonLoading(false);
    }, 2000);
  }, [buttonLoading]);

  useEffect(() => {
    setAnswerCount([[]]);
  }, []);

  const activeQuestions = useMemo(() => {
    const dataQuestions = data.questions.filter(
      (questions) => questions.deactivate === false
    );
    return dataQuestions;
  }, []);

  return isLoading ? (
    <Box sx={{ left: "50%", position: "absolute", top: "50%" }}>
      <Loading loading={isLoading} />
    </Box>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isTabletSize ? (
        <>
          <ProgressBar
            currentQuestion={answerCount[0] ? answerCount[0].length : 0}
            totalQuestions={countTotalMandatoryQuestions(activeQuestions)}
            isSection={false}
          />
          <Title title={getFieldLang(data, "title", lang)} />
        </>
      ) : (
        <Title title={getFieldLang(data, "title", lang)}>
          <ProgressBar
            currentQuestion={answerCount[0] ? answerCount[0].length : 0}
            totalQuestions={countTotalMandatoryQuestions(activeQuestions)}
            isSection={false}
          />
        </Title>
      )}
      <Container sx={containerDescStyle}>
        <DangerousDiv
          dangerouslySetInnerHTML={{
            __html: getFieldLang(data, "description", lang),
          }}
        />
      </Container>
      {activeQuestions.map((item: Question, index) => {
        const questionId = item.id || index;
        const questionTitle = getFieldLang(item, "title", lang);
        const questinDescription = getFieldLang(item, "description", lang);
        const questionOptions = item.options;
        const questionOptionsOthers = item.options_others;
        const isQuestionRequired = item.is_required;
        const ratingIndicators = item.rating_indicators;
        const questionNo = index + 1;

        switch (item?.type) {
          case "textbox":
            return (
              <TextFieldQuestion
                key={index}
                qid={questionId}
                questionTitle={questionTitle}
                questionDescription={questinDescription}
                required={isQuestionRequired}
                setValueAnswer={setValue}
                register={register}
                errors={errors}
                section={0}
                resetField={resetField}
                trigger={trigger}
                questionNo={questionNo}
              />
            );
          case "textarea":
            return (
              <TextAreaQuestion
                key={index}
                qid={questionId}
                required={isQuestionRequired}
                questionTitle={questionTitle}
                textAreaPlaceholder={questionTitle}
                textAreaRows={2}
                setValueAnswer={setValue}
                register={register}
                errors={errors}
                section={0}
                resetField={resetField}
                trigger={trigger}
                questionNo={questionNo}
              />
            );
          case "radio":
            return (
              <RadioQuestion
                key={index}
                qid={questionId}
                questionTitle={questionTitle}
                questionDescription={questinDescription}
                questionOption={questionOptions}
                questionImage={undefined}
                required={isQuestionRequired}
                setValueAnswer={setValue}
                control={control}
                resetField={resetField}
                errors={errors}
                setFocus={setFocus}
                questionOptionsOthers={questionOptionsOthers}
                section={0}
                trigger={trigger}
                questionNo={questionNo}
              />
            );
          case "checkbox":
            return (
              <CheckBoxQuestion
                key={index}
                qid={questionId}
                questionTitle={questionTitle}
                questionDescription={questinDescription}
                questionOption={questionOptions}
                questionImage={undefined}
                required={isQuestionRequired}
                setValueAnswer={setValue}
                control={control}
                resetField={resetField}
                getValues={getValues}
                errors={errors}
                questionOptionsOthers={questionOptionsOthers}
                section={0}
                trigger={trigger}
                questionNo={questionNo}
              />
            );
          case "dropdown":
            return (
              <DropDownCard
                key={index}
                qid={questionId}
                required={isQuestionRequired}
                questionTitle={questionTitle}
                questionImage={""}
                inputLabel={questionTitle}
                dropdownItems={questionOptions}
                setValueAnswer={setValue}
                register={register}
                errors={errors}
                section={0}
                trigger={trigger}
                questionNo={questionNo}
              />
            );
          case "rating_choice":
            return (
              <RatingScore
                key={index}
                qid={questionId}
                required={isQuestionRequired}
                imageRequired={false}
                questionTitle={questionTitle}
                questionOption={questionOptions}
                ratingIndicators={ratingIndicators}
                questionImage={""}
                setValueAnswer={setValue}
                control={control}
                errors={errors}
                section={0}
                trigger={trigger}
                questionNo={questionNo}
              />
            );
          default:
            return null;
        }
      })}

      <Container sx={containerButtonStyle}>
        <CommonPrimaryButton
          type="submit"
          width="160px"
          disabled={buttonLoading}
          onClick={() => setCheckError(!checkError)}
        >
          Finish
        </CommonPrimaryButton>
      </Container>
    </form>
  );
};

export default OnePage;
