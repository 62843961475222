import { SxProps, Theme } from "@mui/system";
import { inputLabelStyle, outlineInputStyle } from "../common/Style";
import styled from "styled-components";
import { Box, Checkbox } from "@mui/material";

export const radioGroupStyle = { gap: "16px" };
export const formControlLabelStyle = (checked: boolean): SxProps<Theme> => ({
  height: "64px",
  marginX: "0px",
  border: `1px solid ${checked ? "#1AE5BC" : "#393F4B"}`,
  borderRadius: "16px",
  color: checked ? "#1AE5BC" : "#FFFFFF",
  backgroundColor: checked ? "#1AE5BC14" : null,
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
  paddingX: "8px",
});

export const CheckBoxAster = styled(Checkbox)`
  &.MuiButtonBase-root {
    color: #fff;
    &.Mui-checked {
      color: #1ae5bc;
    }
  }
`;

export const CheckBoxAsterOther = styled(Checkbox)`
  &.MuiButtonBase-root {
    color: #fff;
    &.Mui-checked {
      color: #1ae5bc;
    }
  }
  &.MuiCheckbox-root {
    align-self: start;
    margin-top: 8px;
  }
`;

export const BoxStyle = styled(Box)`
  text-align: start;
  margin-top: 20px;
`;
export const checkboxOtherTextFieldStyle = {
  width: "570px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: " 4px",
  marginY: "5px",
  marginX: "0px",
  border: "none",
  borderRadius: "10px",
  "& input::placeholder": {
    color: "#FFFFFF",
  },
  "& .MuiInputBase-root": {
    color: "#FFFFFF",
    borderRadius: "16px",
    background: " rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(10px)",
    marginY: "5px",
    marginX: "0px",
  },
  "& .MuiOutlinedInput-root": outlineInputStyle,
  "& .MuiInputLabel-root.Mui-focused": inputLabelStyle,
};
export const checkboxLabelOtherStyle = (checked: boolean): SxProps<Theme> => ({
  marginX: "0px",
  borderRadius: "16px",
  color: checked ? "#1AE5BC" : "#FFFFFF",
  backgroundColor: checked ? "#1AE5BC14" : null,
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
  paddingX: "8px",
  height: "136px",
  border: `1px solid ${checked ? "#1AE5BC" : "#393F4B"}`,
  display: "flex",
  "& .MuiFormControlLabel-root span": {
    boxSizing: "border-box",
    width: "90%",
  },
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
});
