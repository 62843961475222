import { useEffect, useMemo, useState } from "react";
import {
  useGenerateToken,
  useGetInqueryQuestion,
} from "../../services/home/home-query";
import {
  useRouter,
  setQuestionList,
  getQuestionList,
  setRedirectUrl,
  setLang,
  setSurveyGroupID,
  getSurveyGroupID,
  getRedirectUrl,
  getToken,
  setBase64Img,
  resetLatestSectionNo,
} from "../../utils/helper";
import Survey from "./Components/Survey/index";
import { Container, ContainerLoading } from "./style";
import Loading from "../../components/common/Loading";
import base64ErrorImg from "../../constants/images/error_image.json";

const Home = () => {
  const { query } = useRouter();
  const { data: responseToken, mutate: genToken } = useGenerateToken();
  const { data: responseQuestion, mutate: getInqueryQuestion } =
    useGetInqueryQuestion();
  const [readyRender, setReadyRender] = useState(false);
  const token = getToken();
  const redirectUrl = query?.redirect_url || getRedirectUrl();
  const surveyGroupId = query?.survey_group_id || getSurveyGroupID();
  setRedirectUrl(redirectUrl);
  setSurveyGroupID(surveyGroupId);
  setLang(query?.lang || "en");
  setBase64Img(base64ErrorImg.connection_lost_base64);

  useMemo(() => {
    resetLatestSectionNo();
  }, []);

  useEffect(() => {
    genToken({
      channel: query?.channel || "",
      ref_id: query?.ref_id || "",
      surveyGroupId: Number(query?.survey_group_id) || 0,
      surveySubgroupId: Number(query?.survey_subgroup_id) || 1,
    });
  }, [genToken, query]);

  useEffect(() => {
    if (responseQuestion) {
      setReadyRender(true);
    }
  }, [responseQuestion, setReadyRender]);

  useEffect(() => {
    if (token && responseToken) {
      getInqueryQuestion(
        {
          surveyGroupId:
            Number(query?.survey_group_id) || Number(surveyGroupId),
          surveySubgroupId: Number(query?.survey_subgroup_id) || 1,
          includeDraftConsents: true,
          includeDraftAnswers: true,
        },
        {
          onSuccess: (data) => {
            setQuestionList(data);
          },
        }
      );
    }
  }, [responseToken, getInqueryQuestion]);

  const data = getQuestionList();

  return (
    <Container>
      {!readyRender ? (
        <ContainerLoading>
          <Loading loading={!readyRender} />
        </ContainerLoading>
      ) : (
        <Survey data={data} />
      )}
    </Container>
  );
};
export default Home;
