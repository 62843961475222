import styled from "styled-components";
import { GRAY_F9FAFB } from "../../constants/colors";

export const Container = styled.div`
  background-color: ${GRAY_F9FAFB};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // width: 100vw;
`;

export const ContainerLoading = styled.div`
  height: 80vh;
  z-idnex: 100;
  background-color: ${GRAY_F9FAFB};
`;
