import { CircularProgress } from "@mui/material";

const LoadingButton = () => {
  return (
    <CircularProgress
      sx={{
        color: "#7DE8C0",
        borderRadius: "100%",
        boxShadow: "inset 0 0 0px 5px rgba(125, 232, 192, 0.3)",
        backgroundColor: "transparent",
      }}
      thickness={5}
    />
  );
};

export default LoadingButton;
