import { Container } from "./Component/Content/style";
import Content from "./Component/Content";
import Footer from "./Component/Footer";

const Retry = () => {
  return (
    <Container>
      <Content />
      {/* <Footer /> */}
    </Container>
  );
};

export default Retry;
