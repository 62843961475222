export const countTotalMandatoryQuestions = (questions: Question[]): number => {
  const countMandatoryQuestionsOfSection = (questions: Question[]): number => {
    return questions?.filter((question) => question.is_required).length;
  };

  const mandatoryQuestionsCount = countMandatoryQuestionsOfSection(questions);

  const totalQuestions =
    mandatoryQuestionsCount === 0
      ? mandatoryQuestionsCount + 1
      : mandatoryQuestionsCount;

  return totalQuestions;
};
