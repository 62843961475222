import { useMutation, useQueryClient } from "react-query";
import {
  setToken,
  getTraceParent,
  clearSession,
  setRefreshToken,
  setDraftAnswerSubmit,
  getDraftId,
  getLang,
  useRouter,
  getSurveyGroupID,
  setResponseQuestionaire,
  setLatestSectionNo,
  setDraftId,
} from "../../utils/helper";
import {
  InqueryQuestionBody,
  SaveQueryParamsType,
  GenerateTokenType,
  SubmitAnswerBody,
  GenerateRefreshTokenType,
  SubmitDraftAnswerBody,
} from "./home-type";
import { api } from "../../utils/api";
import { useMemo } from "react";
import humps from "humps";

export const QUESTIONAIRE_INQUIRY = `survey/questionnaire-inquiry/questions`;
export const SUBMIT_ANSWER = `survey/orchrestrator/submit-answer`;
export const DRAFT_SUBMIT_ANSWER = `survey/orchrestrator/submit-draft-answer`;
export const SETTING = "setting";
export const GENERATE_TOKEN = "partner/auth/token/generate";
export const GENERATE_REFRESH_TOKEN = "partner/auth/token/refresh";

const validateSaveQueryParams = (params: SaveQueryParamsType) => {
  const { authorizationCode, channel, lang, surveyName } = params;

  if (!authorizationCode || authorizationCode?.length === 0) {
    return Promise.reject(null);
  }

  if (!surveyName || surveyName?.length === 0) {
    return Promise.reject(null);
  }

  if (
    !channel ||
    channel?.length === 0 ||
    (channel !== "PAOTANG" && channel !== "NEXT")
  ) {
    return Promise.reject(null);
  }

  if (!lang || lang?.length === 0 || (lang !== "TH" && lang !== "EN")) {
    return Promise.reject(null);
  }
};

export const useSaveQueryParams = () => {
  let p;
  const queryClient = useQueryClient();
  return useMutation(
    async (params: SaveQueryParamsType) => {
      await validateSaveQueryParams(params);
      p = params;
    },
    {
      onSuccess: () => {
        const { channel, lang, surveyName } = p;
        queryClient.setQueryData<SettingType>([SETTING], {
          channel,
          lang,
          surveyName,
        });
      },
    }
  );
};

export const useGetQueryParams = () => {
  const queryClient = useQueryClient();
  let data = queryClient.getQueryData<SettingType>([SETTING]);
  if (!data) {
    data = {
      channel: "",
      lang: "",
      surveyName: "",
    };
  }
  return data;
};

export const useGenerateToken = () => {
  return useMutation(
    async (params: GenerateTokenType) => {
      clearSession();
      getTraceParent();

      const { data } = await api.gt.post(GENERATE_TOKEN, params);
      return data.data;
    },
    {
      onSuccess: (data: any) => {
        setToken(data.token);
        setRefreshToken(data.refreshToken);
      },
    }
  );
};

export const useGenerateRefreshToken = () => {
  return useMutation(
    async (params: GenerateRefreshTokenType) => {
      clearSession();
      getTraceParent();
      const { data } = await api.gt.post(GENERATE_REFRESH_TOKEN, params);
      return data.data;
    },
    {
      onSuccess: (data: any) => {
        setToken(data.token);
        setRefreshToken(data.previousRefreshToken);
      },
    }
  );
};

const getQuestionList = async (url: string) => {
  const { data } = await api.public.get(url);
  return data;
};

export const useGetInqueryQuestion = () => {
  const lang = useMemo(() => {
    return getLang() || "en";
  }, []);

  const { query } = useRouter();
  const surveyGroupId = getSurveyGroupID();

  return useMutation(async (params: InqueryQuestionBody) => {
    const path = QUESTIONAIRE_INQUIRY;
    const { data } = await api.gt.post(path, params);
    const response: any = data?.data;
    setResponseQuestionaire(response.draftAnswer.draftAnswers);
    setDraftId(response.draftAnswer.draftId);
    if (response.draftAnswer.sectionNo) {
      setLatestSectionNo(
        response.draftAnswer.sectionNo == 0
          ? 0
          : response.draftAnswer.sectionNo - 1
      );
    }
    if (data.code === 0 && response.draftAnswer.draftAnswers) {
      setDraftAnswerSubmit({
        survey_group_id:
          Number(query?.survey_group_id) || Number(surveyGroupId),
        channel: "aster",
        lang: lang,
        id: getDraftId(),
        consents: [
          {
            consent_id: 1,
            allowed: true,
          },
        ],
        answers: humps.decamelizeKeys(response.draftAnswer.draftAnswers),
      });
    }

    if (data.code === 0 && response.questionUrl) {
      return getQuestionList(response.questionUrl);
    }
    throw new Error("error");
  });
};

export const useSubmitAnswer = () => {
  return useMutation(async (params: SubmitAnswerBody) => {
    const path = SUBMIT_ANSWER;
    const { data } = await api.gt.post(path, params);
    return data;
  });
};

export const useSubmitDraftAnswer = () => {
  return useMutation(async (params: SubmitDraftAnswerBody) => {
    const path = DRAFT_SUBMIT_ANSWER;
    const { data } = await api.gt.post(path, params);
    return data;
  });
};
