import { LoadingProps } from "./type";
import { Box } from "@mui/material";
import LoadingButton from "../../LoadingButton";

const Loading = (props: LoadingProps) => {
  const { loading } = props;
  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "50%",
            left: "50%",
          }}
        >
          <LoadingButton />
        </Box>
      ) : null}
    </>
  );
};

export default Loading;
