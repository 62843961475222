import { Box } from "@mui/material";
import { useLayout } from "../../../context/LayoutContext";

const SurveyHeader = () => {
  const { isTabletSize } = useLayout();
  return (
    <Box
      className={isTabletSize ? "bg-tablet" : ""}
      width={"100%"}
      height={"112px"}
      sx={{
        display: "flex",
        flexDirection: "column",
        background: isTabletSize ? "" : "srgb(0,0,0,0)",
        justifyContent: "center",
        position: isTabletSize ? "sticky" : "",
        top: isTabletSize ? "0" : "",
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <svg
          width="112"
          height="32"
          viewBox="0 0 112 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_330_1978)">
            <path
              d="M30.3593 32H21.8793L11.3481 0H19.826L30.3593 32Z"
              fill="#E6F4F7"
            />
            <path
              d="M19.8109 0H11.3309L0.799805 32H9.27978L19.8109 0Z"
              fill="url(#paint0_linear_330_1978)"
            />
          </g>
          <path
            d="M31.9611 24.1582H37.652C37.9072 26.2686 38.8752 27.4792 41.5345 27.4792C43.8938 27.4792 44.9745 26.5346 44.9745 24.922C44.9745 23.3094 43.6662 22.635 40.5069 22.1436C34.6438 21.199 32.3632 19.4056 32.3632 14.8359C32.3632 9.93858 36.584 7.53247 41.0962 7.53247C46.0106 7.53247 49.657 9.41311 50.2037 14.7912H44.5958C44.2576 12.8659 43.2449 11.9639 41.1558 11.9639C39.1901 11.9639 38.0349 12.9425 38.0349 14.3891C38.0349 15.8358 39.0922 16.4506 42.2961 16.942C47.821 17.7951 50.8568 19.2737 50.8568 24.2902C50.8568 29.3066 47.4168 31.9999 41.577 31.9999C35.6054 32.0042 32.1653 29.2215 31.959 24.1582H31.9611Z"
            fill="#E6F4F7"
          />
          <path
            d="M66.2654 27.193C67.2394 27.193 68.0877 27.1301 68.8103 27.0045V31.623C67.2394 31.8743 65.6685 32 64.0976 32C62.2439 32 60.7672 31.7801 59.6676 31.3402C58.5993 30.9004 57.7982 30.1149 57.2641 28.9838C56.7614 27.8528 56.51 26.2661 56.51 24.2239V11.7822H52.457V6.97523H56.51V0.848633H62.5423V6.97523H68.5747V11.7822H62.5423V23.4699C62.5423 24.978 62.8094 25.9834 63.3435 26.4861C63.909 26.9573 64.883 27.193 66.2654 27.193Z"
            fill="#E6F4F7"
          />
          <path
            d="M70.4102 20.078V19.7206C70.4102 12.3235 75.3245 7.52832 81.7535 7.52832C87.4508 7.52832 92.5076 11.0641 92.5076 19.4504V21.2438H76.6286C76.7967 25.1625 78.7815 27.3814 82.1131 27.3814C84.9383 27.3814 86.3296 26.0837 86.7083 24.1094H92.4885C91.7716 29.1748 87.9762 32 81.9408 32C75.2564 32 70.4102 27.5622 70.4102 20.078ZM86.5594 17.2506C86.3488 13.7085 84.6617 12.0087 81.7535 12.0087C79.0113 12.0087 77.1583 13.934 76.6924 17.2506H86.5615H86.5594Z"
            fill="#E6F4F7"
          />
          <path
            d="M94.1079 8.541H100.222V13.0235C101.618 9.88766 103.766 8.22827 107.353 8.18359V14.2382C102.841 14.1893 100.222 15.7615 100.222 20.2439V32.0001H94.1079V8.541Z"
            fill="#E6F4F7"
          />
          <defs>
            <linearGradient
              id="paint0_linear_330_1978"
              x1="5.01098"
              y1="30.3397"
              x2="14.4801"
              y2="4.35563"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3482E6" />
              <stop offset="0.34" stopColor="#57C4F1" />
              <stop offset="1" stopColor="#53B27D" />
            </linearGradient>
            <clipPath id="clip0_330_1978">
              <rect
                width="29.5593"
                height="32"
                fill="white"
                transform="translate(0.799805)"
              />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </Box>
  );
};

export default SurveyHeader;
