import { Container } from "@mui/material";
import {
  containerWidth,
  lgMediaQuery,
  mdMediaQuery,
  smMediaQuery,
} from "../Style";
import { ReactNode } from "react";

interface SectionHeaderType {
  text: string;
  children?: ReactNode;
}

const SectionHeader = ({ text, children }: SectionHeaderType) => {
  return (
    <Container
      sx={{
        width: containerWidth,
        color: "#FFF",
        marginBottom: "8px",
        textAlign: "start",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "600",
        display: "flex",
        fontFamily: "Poppins-Regular",
        [smMediaQuery]: {
          minWidth: "240px",
          width: "100vw",
          paddingX: "24px",
        },
        [mdMediaQuery]: {
          maxWidth: "834px",
          width: "100vw",
          paddingX: "24px",
        },
        [lgMediaQuery]: {
          width: "690px",
          maxWidth: containerWidth,
          paddingX: "24px",
        },
      }}
    >
      {text}
      {children}
    </Container>
  );
};

export default SectionHeader;
