import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imageError from "../../../../constants/images/Disconnect@3x.png";
import { useGetError } from "../../../../services/error/error-query";
import {
  CardContainer,
  ContainerDetail,
  ContainerTitle,
  StyledImage,
} from "./style";
import { ErrorRenderType } from "./type";
import LoadingButton from "../../../../components/LoadingButton";
import { getPayload, clearSession } from "../../../../utils/helper";
import { useSubmitAnswer } from "../../../../services/home/home-query";
import { Typography } from "@mui/material";
import CommonPrimaryButton from "../../../../components/common/CommonPrimaryButton";

const Content = () => {
  const { data: dataError } = useGetError();
  const navigate = useNavigate();
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const { data: resAnswers, mutate: submitAnswer } = useSubmitAnswer();

  const error = useMemo(() => {
    let error: Partial<ErrorRenderType> = {};
    if (dataError) {
      const { status, data } = dataError;
      const { exp, code, message } = data || {};
      if (status && status === 502) {
        error = {
          title: "ไม่สามารถส่งคำตอบได้ในขณะนี้",
          detail: "กรุณาลองใหม่อีกครั้ง",
          isErrorCode: true,
          errorCode: "9999999",
          errorMessage: "Service Timeout",
        };
      } else if (exp && exp === "token expired") {
        error = {
          title: "หมดเวลาทำแบบทดสอบ",
          detail: "กรุณาลองใหม่อีกครั้ง",
          isErrorCode: false,
        };
      } else {
        error = {
          title: "ไม่สามารถส่งคำตอบได้ในขณะนี้",
          detail: "กรุณาลองใหม่อีกครั้ง",
          isErrorCode: true,
          errorCode: code || "9999999",
          errorMessage: message || "Unknown Error",
        };
      }
    }
    return error;
  }, [dataError]);

  const Retry = () => {
    const payload = getPayload();
    if (payload) {
      const convert = JSON.parse(payload);
      submitAnswer(convert);
    }
  };

  useEffect(() => {
    if (resAnswers && resAnswers.code === 0) {
      clearSession();
      navigate("/finish");
    }
  }, [resAnswers, submitAnswer]);

  return (
    <CardContainer>
      <StyledImage
        src={imageError}
        alt={`${error?.title} ${error?.detail}`}
        draggable={false}
      />
      <ContainerTitle>
        <Typography fontSize={"32px"} fontWeight={700} color={"#FFF"}>
          Unable to send your response
        </Typography>
      </ContainerTitle>
      <ContainerDetail>
        {isLoadingButton ? (
          <LoadingButton />
        ) : (
          <CommonPrimaryButton
            disabled={isLoadingButton === false ? true : false}
            onClick={Retry}
          >
            Retry
          </CommonPrimaryButton>
        )}
      </ContainerDetail>
    </CardContainer>
  );
};

export default Content;
