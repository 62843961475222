import { InputLabel } from "@mui/material";
import styled from "styled-components";

export const dropdownStyle = {
  marginTop: "20px",
  "& .MuiInputBase-root": {
    borderRadius: "16px",
    color: "#FFFFFF",
    background: "rgba(0,0,0,0.24)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#393F4B",
    },
    "&:hover fieldset": {
      borderColor: "#393F4B",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#393F4B",
      color: "#AEB4C1",
      border: "1px solid #393F4B",
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#AEB4C1",
  },
  "& .MuiSelect-icon": {
    color: "#AEB4C1",
  },
};

export const InputLabelDropdown = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: #aeb4c1;
  }
`;

export const dropdownMenuStyle = {
  marginX: "5px",
  "&.Mui-selected": {
    color: "#17D1AA",
    backgroundColor: "#1a1c20",
    marginX: "5px",
    borderRadius: "10px",
  },
};
