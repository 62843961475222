import { Container, Typography } from "@mui/material";
import {
  containerWidth,
  lgMediaQuery,
  mdMediaQuery,
  smMediaQuery,
} from "../Style";
import { ReactNode } from "react";

interface TitleProps {
  title: string;
  children?: ReactNode;
}

const Title = ({ title, children }: TitleProps) => {
  return (
    <Container
      sx={{
        display: "flex",
        [smMediaQuery]: {
          minWidth: "240px",
          maxWidth: "100vw",
          paddingX: "24px",
        },
        [mdMediaQuery]: {
          maxWidth: "834px",
          width: "100vw",
          paddingX: "24px",
        },
        [lgMediaQuery]: {
          width: "690px",
          maxWidth: containerWidth,
          paddingX: "24px",
        },
      }}
    >
      <Typography
        sx={{
          background: "linear-gradient(90deg, #52E0AB 0.69%, #0183E8 61.83%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "40px",
          fontWeight: 700,
          lineHeight: "48px",
          textAlign: "start",
          fontStyle: "normal",
        }}
      >
        {title}
      </Typography>
      {children}
    </Container>
  );
};

export default Title;
