//SOLID COLORS//
export const BLACK_212121 = "";
export const BLUE_004C7C = "";
export const BLUE_025BB7 = "";
export const BLUE_00A6E6 = "";
export const GRAY_F8F8F8 = "";
export const GRAY_838383 = "";
export const GRAY_565656 = "";
export const GRAY_E0E0E0 = "";
export const GRAY_EAF0F5 = "";
export const GRAY_F2F2F2 = "";
export const GRAY_F2F4F7 = "";
export const GRAY_CACACA = "";
export const GRAY_F9FAFB = "";
export const GRAY_8A9FAB = "";
export const GRAY_5A6E7B = "";
export const GRAY_BCCCD6 = "";
export const GREEN_70B412 = "";
export const RED_DB0000 = "";
export const WHITE_FFFFFF = "";

//GRADIENT COLORS//
export const GRADIENT_PRIMARY =
  // "linear-gradient(62.12deg, #007BC4 0%, #04BAEE 100%)";
  "rgba(10, 19, 51, 0.40)";
