import { Box, Container, Link, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  inputLabelStyle,
  inputBaseStyle,
  inputLabelFocusStyle,
  outlineInputStyle,
  containerStyle,
  OptionalQuestionStyle,
  QuestionTitleText,
  QuestionDescriptionText,
  inputLabelErrorStyle,
  inputBaseErrorStyle,
  outlineInputErrorStyle,
  inputLabelFocusErrorStyle,
  InlineRequireText,
  DangerousDivTiTle,
} from "../common/Style";
import {
  countTextBoxStyle,
  countTextStyle,
  inputPropsErrorStyle,
  inputPropsStyle,
} from "./style";
import { useLayout } from "../../context/LayoutContext";
import { QuestionImages } from "../common/Image";
import { TextAreaQuestionProps } from "./type";

const TextAreaQuestion = ({
  qid,
  required,
  questionTitle,
  questionDescription,
  hyperlink,
  textAreaPlaceholder,
  setValueAnswer,
  stateAnswer,
  questionImage,
  register,
  errors,
  resetField,
  section,
  trigger,
  questionNo,
}: TextAreaQuestionProps) => {
  const { setAnswerCount } = useLayout();
  const [countText, setCountText] = useState(stateAnswer?.opt_long_text ?? "");
  const maxLengthText = 1000;
  const [checkError, setCheckError] = useState(
    errors[qid! - 1] && errors[qid! - 1]?.type === "required"
  );
  const propsStyle = checkError ? inputPropsErrorStyle : inputPropsStyle;
  const labelStyle = checkError ? inputLabelErrorStyle : inputLabelStyle;
  const baseStyle = checkError ? inputBaseErrorStyle : inputBaseStyle;
  const outlineStyle = checkError ? outlineInputErrorStyle : outlineInputStyle;
  const focusStyle = checkError
    ? inputLabelFocusErrorStyle
    : inputLabelFocusStyle;

  useEffect(() => {
    setCheckError(errors[qid! - 1] && errors[qid! - 1]?.type === "required");
  }, [errors[qid! - 1]]);

  const setAnswerCountTextArea = (required: boolean, answer: string) => {
    if (required) {
      setAnswerCount((prev) => {
        const updatedCount = [...prev];
        if (answer.trim() !== "") {
          if (!updatedCount[section].includes(qid)) {
            updatedCount[section].push(qid);
          }
        } else {
          updatedCount[section] = updatedCount[section].filter(
            (id) => id !== qid
          );
        }
        return updatedCount;
      });
    }
  };

  const { ref, ...rest } = register(`${qid! - 1}`, {
    required: required,
  });
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const answer = e.target.value.trim() === "" ? "" : e.target.value;
    const text = e.target.value;
    setValueAnswer(`${qid - 1}`, {
      q_id: qid,
      opt_id: null,
      opt_ids: null,
      opt_short_text: null,
      opt_long_text: answer,
      opt_others: null,
    });
    setAnswerCountTextArea(required, answer);
    if (answer.length === 0) {
      resetField(`${qid! - 1}`);
    }
    setCountText(text);
    trigger(`${qid! - 1}`);
  };

  useMemo(() => {
    if (stateAnswer?.opt_long_text && stateAnswer?.opt_long_text.length > 0) {
      setAnswerCountTextArea(required, stateAnswer?.opt_long_text);
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid!,
        opt_id: null,
        opt_ids: null,
        opt_short_text: null,
        opt_long_text: stateAnswer?.opt_long_text,
        opt_others: null,
      });
    }
  }, []);

  return (
    <>
      <Container sx={containerStyle} id={`${qid}`}>
        <Box sx={{ textAlign: "left" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <DangerousDivTiTle
              dangerouslySetInnerHTML={{
                __html: `${questionNo}. ${questionTitle}`,
              }}
            />
            {!required && (
              <OptionalQuestionStyle> (optional)</OptionalQuestionStyle>
            )}
          </Box>
          <Box>
            <QuestionDescriptionText>
              {questionDescription}
            </QuestionDescriptionText>
            <Link href={hyperlink}>{hyperlink}</Link>
            {questionImage && <QuestionImages image={questionImage} />}

            <TextField
              fullWidth
              multiline
              minRows={4}
              placeholder={textAreaPlaceholder}
              defaultValue={stateAnswer?.opt_long_text}
              inputProps={{ maxLength: maxLengthText }}
              {...rest}
              InputProps={{ style: propsStyle, readOnly: false }}
              onChange={handleInputChange}
              InputLabelProps={{
                style: labelStyle,
              }}
              sx={{
                "& .MuiInputBase-root": baseStyle,
                "& .MuiOutlinedInput-root": outlineStyle,
                "& .MuiInputLabel-root.Mui-focused": focusStyle,
              }}
            />
            <Box sx={countTextBoxStyle}>
              <Typography sx={countTextStyle}>
                {countText.length}/{maxLengthText}
              </Typography>
              {errors[qid! - 1] && errors[qid! - 1]?.type === "required" && (
                <InlineRequireText>This is required</InlineRequireText>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default TextAreaQuestion;
