import { ReactNode, useCallback, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { QueryClient, QueryClientProvider } from "react-query";
import * as paths from "../constants/paths";
import { ERROR } from "../services/error/error-query";
import sha256 from "crypto-js/sha256";

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  return useMemo(() => {
    return {
      push: navigate,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search.slice(1)),
        ...params,
      },
      location,
      navigate,
    };
  }, [location, navigate, params]);
};

export const clearSession = () => {
  sessionStorage.clear();
};

export const clearLocal = () => {
  localStorage.clear();
};

export const resetToken = () => {
  sessionStorage.removeItem("AUTH_TOKEN");
};

export const resetLatestSectionNo = () => {
  localStorage.removeItem("LATEST_SECTION_NO");
};

export const setToken = (token: string) => {
  sessionStorage.setItem("AUTH_TOKEN", token);
};

export const getToken = () => {
  return sessionStorage.getItem("AUTH_TOKEN");
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem("REFRESH_TOKEN", token);
};

export const getQuestionList = () => {
  return jsonParseLocalItem("QUESTION_LIST");
};

export const setQuestionList = (question: any) => {
  return localStorage.setItem("QUESTION_LIST", JSON.stringify(question));
};

export const setDraftAnswerSubmit = (answer: any) => {
  return localStorage.setItem("DRAFT_SUBMIT", JSON.stringify(answer));
};

export const getDraftAnswerSubmit = () => {
  return jsonParseLocalItem("DRAFT_SUBMIT");
};

export const setDraftId = (id: string) => {
  localStorage.setItem("DRAFT_ID", id);
};

export const getDraftId = () => {
  return localStorage.getItem("DRAFT_ID");
};

export const setRedirectUrl = (url: any) => {
  sessionStorage.setItem("REDIRECT_URL", url);
};

export const getRedirectUrl = () => {
  return sessionStorage.getItem("REDIRECT_URL");
};

export const setSurveyGroupID = (id) => {
  sessionStorage.setItem("SURVEY_GROUP_ID", id);
};

export const getSurveyGroupID = () => {
  return sessionStorage.getItem("SURVEY_GROUP_ID");
};

export const setLatestSectionNo = (sectionNo) => {
  localStorage.setItem("LATEST_SECTION_NO", sectionNo);
};

export const getLatestSectionNo = () => {
  return localStorage.getItem("LATEST_SECTION_NO");
};

export const setBase64Img = (image) => {
  localStorage.setItem("CONNECTION_LOST_IMG_BASE64", image);
};

export const getBase64Img = () => {
  return localStorage.getItem("CONNECTION_LOST_IMG_BASE64");
};

const jsonParseLocalItem = (localKey: string) => {
  try {
    return JSON.parse(localStorage.getItem(localKey) || "");
  } catch {
    return undefined;
  }
};

export const getRefreshToken = () => {
  return localStorage.getItem("REFRESH_TOKEN");
};

export const resetPayload = () => {
  sessionStorage.removeItem("PAYLOAD");
};

export const setPayload = (payload: string) => {
  sessionStorage.setItem("PAYLOAD", payload);
};

export const getPayload = () => {
  return sessionStorage.getItem("PAYLOAD");
};

export const getTraceId = () => {
  return sessionStorage.getItem("TRACE_ID");
};

export const setTraceId = (traceId: string) => {
  sessionStorage.setItem("TRACE_ID", traceId);
};

export const genTraceId = () => {
  const traceId = sha256(Math.random().toString()).toString().substring(0, 32);
  setTraceId(traceId);

  return traceId;
};

export const setLang = (lang: any) => {
  localStorage.setItem("LANG", lang.toLowerCase());
};

export const getLang = () => {
  return localStorage.getItem("LANG");
};

export const setResponseQuestionaire = (response) => {
  localStorage.setItem("RESPONSEQUESTIONAIRE", JSON.stringify(response));
};

export const getResponseQuestionaire = () => {
  return localStorage.getItem("RESPONSEQUESTIONAIRE");
};

export const getFieldLang = (data: any, field: string, lang: string) => {
  if (!data || !data[`${field}_${lang}`] || data === undefined) return "";
  return data[`${field}_${lang}`];
};

export const closeWebView = () => {
  const command = "closeWebview";

  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;

  if (isAndroid) {
    // @ts-ignore
    window.JSBridge.closeWebview("");
  } else if (window.webkit) {
    window.webkit.messageHandlers.observer.postMessage({
      command,
    });
  }
};

export const getTraceParent = () => {
  const version = "00";

  let traceId = getTraceId();

  if (!traceId) {
    traceId = genTraceId();
  }

  const parentId = sha256(Math.random().toString()).toString().substring(0, 16); // 16 hex string

  const traceFlags = "01";

  return `${version}-${traceId}-${parentId}-${traceFlags}`;
};

type CustomQueryClientProviderProp = {
  children?: ReactNode;
};
const client = new QueryClient();
export const CustomQueryClientProvider = (
  props: CustomQueryClientProviderProp
) => {
  const { children } = props;
  const { navigate } = useRouter();

  const onErrorHandler = useCallback(
    (error: any) => {
      const { status, code, message, exp } = error;
      console.log("error", error);
      client.setQueryData([ERROR], {
        status,
        data: {
          code,
          message,
          exp,
        },
      });
      navigate(paths.error());
    },
    [navigate]
  );

  useMemo(() => {
    client.setDefaultOptions({
      queries: {
        cacheTime: 30 * 1000,
        staleTime: 30 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: 0,
        onError: onErrorHandler,
      },
      mutations: {
        retry: 0,
        onError: onErrorHandler,
      },
    });
  }, [onErrorHandler]);

  return (
    <QueryClientProvider client={client}>
      {children && children}
    </QueryClientProvider>
  );
};
