import CommonPrimaryButton from "../../../common/CommonPrimaryButton";
import Text from "../../../common/Text";
import {
  AcknowledgeBox,
  CheckboxAcknowledge,
  GridBox,
  GridContainer,
} from "./style";
import { AcknowledgeProps } from "./type";

const Acknowledge = ({
  consents,
  isStart,
  clickConsent,
  onStartSurvey,
}: AcknowledgeProps) => {
  return (
    <GridContainer container>
      <GridBox item xs={12} sm={12} md={12} lg={12}>
        {consents.map((item, index) => {
          return (
            <AcknowledgeBox key={index}>
              <CheckboxAcknowledge onChange={(e) => clickConsent(e, item)} />
              <Text size="16px" lineHeight="normal" color="#FFF">
                {item.consent_text_en}
              </Text>
            </AcknowledgeBox>
          );
        })}
      </GridBox>

      <GridBox item xs={12} sm={12} md={12} lg={12}>
        <CommonPrimaryButton
          disabled={!isStart}
          onClick={onStartSurvey}
          width="319px"
          height="48px"
          margin="0px 4px 0px"
        >
          <Text
            color="#0E1013"
            type="Bold"
            size="16px"
            lineHeight="24px"
            weight={600}
          >
            Start Survey
          </Text>
        </CommonPrimaryButton>
      </GridBox>
    </GridContainer>
  );
};

export default Acknowledge;
