import { Box } from "@mui/material";
import SurveyHeader from "../common/SurveyHeader";
import { MainLayoutProp } from "./type";

const MainLayout = (props: MainLayoutProp) => {
  const { component: Component } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        minHeight: "100vh",
      }}
    >
      <SurveyHeader />
      <Component />
    </Box>
  );
};

export default MainLayout;
