import { Typography } from "@mui/material";

import { TextProps } from "./type";
const Text = (props: TextProps) => {
  const {
    children,
    className,
    color,
    lineHeight,
    size,
    talk,
    weight,
    transform = "none",
    fontFamily = "Poppins-Regular",
  } = props;
  return (
    <Typography
      className={className}
      color={color || "black"}
      lineHeight={lineHeight || "24px"}
      fontSize={size || "16px"}
      fontFamily={fontFamily}
      aria-label={talk}
      fontWeight={weight || 400}
      sx={{ textTransform: transform }}
    >
      {children}
    </Typography>
  );
};
export default Text;
