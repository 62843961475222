import { LinearProgress } from "@mui/material";
import {
  Container,
  LabelContainer,
  ProgressBarStyle,
  SectionLabel,
} from "./style";
import { RocketProgressBarProps } from "./type";
import { useEffect, useState } from "react";
import { useLayout } from "../../context/LayoutContext";

const ProgressBar = ({
  currentQuestion,
  totalQuestions,
  isSection,
  currentSection,
  totalSection,
}: RocketProgressBarProps) => {
  const [progress, setProgress] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isTabletSize } = useLayout();

  useEffect(() => {
    const newProgress = (currentQuestion * 100) / totalQuestions;
    setProgress(newProgress);
  }, [currentQuestion, totalQuestions]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const calPosition = () => {
    const initialTop = isTabletSize ? 112 : 145;
    const finalTop = isTabletSize ? 112 : 64;
    const maxScroll = 200;

    const newTop = Math.max(
      finalTop,
      initialTop - (scrollPosition / maxScroll) * (initialTop - finalTop)
    );

    return newTop;
  };

  return (
    <Container
      className={isTabletSize ? "bg-tablet" : ""}
      style={{ top: `${calPosition()}px` }}
      isTabletSize={isTabletSize}
    >
      {isSection && (
        <LabelContainer isTabletSize={isTabletSize}>
          <SectionLabel>
            {currentSection} of {totalSection} Section
          </SectionLabel>
        </LabelContainer>
      )}

      <LinearProgress
        variant="determinate"
        value={progress}
        sx={ProgressBarStyle}
      />
    </Container>
  );
};

export default ProgressBar;
