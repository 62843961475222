import { useEffect, useState } from "react";
import {
  Container,
  StyledCard,
  StyledCardContent,
  StyledContainer,
  HeaderContainer,
  ContentContainer,
  Icon,
  IconBox,
} from "./style";
import Acknowledge from "./components/Acknowledge/Acknowledge";
import { getBase64Img } from "../../utils/helper";

type LandingProps = {
  consents: Array<any>;
  header: string;
  content: string;
  footer: string;
  icon: string;
  submit: (value: string) => void;
};

const Landing = ({
  consents,
  header,
  content,
  footer,
  submit,
  icon,
}: LandingProps) => {
  const [isStart, setIsStart] = useState(false);
  const [isConsent, setIsConsent]: any[] = useState([]);
  const connectionLostErrorImg = getBase64Img();

  const clickConsent = (e, val) => {
    if (e.target.checked) {
      return setIsConsent([...isConsent, { ...val }]);
    } else {
      return setIsConsent(
        isConsent.filter((item) => item.consent_id !== val.consent_id)
      );
    }
  };

  const onStartSurvey = () => {
    return submit(isConsent);
  };

  useEffect(() => {
    const isConsentLength = isConsent.filter(
      (item) => item.consent_required === true
    ).length;
    const consentsRequiredLength = consents.filter(
      (item) => item.consent_required === true
    ).length;
    if (isConsentLength === consentsRequiredLength) {
      return setIsStart(true);
    }
    return setIsStart(false);
  }, [isConsent]);

  return (
    <Container>
      <HeaderContainer
        dangerouslySetInnerHTML={{ __html: header }}
      ></HeaderContainer>
      <StyledCard>
        <IconBox>
          <Icon
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = connectionLostErrorImg || "";
            }}
            src={icon}
          />
        </IconBox>
        <StyledCardContent>
          <ContentContainer
            dangerouslySetInnerHTML={{ __html: content }}
          ></ContentContainer>
        </StyledCardContent>
      </StyledCard>
      <Acknowledge
        consents={consents}
        clickConsent={clickConsent}
        isStart={isStart}
        onStartSurvey={onStartSurvey}
      />
    </Container>
  );
};

export default Landing;
