import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)<{ isTabletSize: boolean }>`
  max-width: ${(props) => (props.isTabletSize ? "" : "240px")};
  width: ${(props) => (props.isTabletSize ? "" : "100%")};
  position: ${(props) => (props.isTabletSize ? "sticky" : "fixed")};
  z-index: 9999;
  text-align: left;
  transform: ${(props) => (props.isTabletSize ? "none" : "translateX(278%)")};
  margin-bottom: 36px;
  padding-inline: ${(props) => (props.isTabletSize ? "24px" : "")};
  background-color: ${(props) =>
    props.isTabletSize ? "rgba(22, 24, 29, 1)" : ""};
  padding-block: ${(props) => (props.isTabletSize ? "12px" : "")};
`;

export const LabelContainer = styled(Box)<{ isTabletSize: boolean }>`
  z-index: 9999;
  padding-bottom: 12px;
`;

export const SectionLabel = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #f3f4f6;
`;

export const ProgressBarStyle = {
  height: "8px",
  width: "100%",
  borderRadius: "999px",
  zIndex: 9999,
  background: "rgba(255, 255, 255, 0.08)",
  "& .MuiLinearProgress-bar": {
    background: "#17D1AA",
    borderRadius: "999px",
  },
};
