import { Container } from "./Component/Content/style";
import Content from "./Component/Content";

const Error = () => {

  return (
    <Container>
      <Content />
      {/* <Footer /> */}
    </Container>
  );
};

export default Error;
