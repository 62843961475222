import { useQuery } from "react-query";

export const ERROR = "error";
export const useGetError = () => {
  return useQuery<HttpResponseErrorType>([ERROR], () => {
    return {
      status: 500,
      data: {
        code: 999999,
        message: "Unknown Error",
      },
    };
  });
};
