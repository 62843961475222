import { Box, Grid, Checkbox } from "@mui/material";
import styled from "styled-components";

export const GridContainer = styled(Grid)`
  align-items: center;
  padding: 32px 24px;
  gap: 24px;
`;

export const GridBox = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px !important;
`;

export const AcknowledgeBox = styled(Box)`
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 40px;
  gap: 16px;
`;

export const CheckboxAcknowledge = styled(Checkbox)`
  width: 24px;
  height: 24px;
  color: #fff !important;

  & .MuiSvgIcon-root {
    background: transparent !important;
    border-radius: 3px;
  }

  &.Mui-checked {
    color: #1ae5bc !important;

    & .MuiSvgIcon-root {
      background: transparent !important;
      border-radius: 3px;
    }
  }

  &:hover {
    background-color: transparent !important;
  }
`;
