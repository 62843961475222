import { CommonSecondaryButtonProps } from "./type";
import { CommonSecondaryButtonStyle } from "./style";
import { ButtonProps } from "@mui/material";

const CommonSecondaryButton = ({
  children,
  width,
  height,
  maxWidth,
  margin,
  ...props
}: CommonSecondaryButtonProps & ButtonProps) => {
  return (
    <CommonSecondaryButtonStyle
      variant="contained"
      width={width}
      height={height}
      maxWidth={maxWidth}
      margin={margin}
      {...props}
    >
      {children}
    </CommonSecondaryButtonStyle>
  );
};

export default CommonSecondaryButton;
