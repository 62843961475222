import { useEffect, useMemo, useState } from "react";
import Text from "../../../../components/common/Text";
import timeoutErrorImg from "../../../../constants/images/sesstion_time_out.png";
import genericErrorImg from "../../../../constants/images/generic_error_v2.svg";
import { useGetError } from "../../../../services/error/error-query";
import {
  clearSession,
  getBase64Img,
  getRedirectUrl,
  getRefreshToken,
  resetToken,
  useRouter,
} from "../../../../utils/helper";
import {
  CardContainer,
  ContainerErrorCode,
  ContainerTitle,
  StyledImage,
} from "./style";
import { ErrorRenderType } from "./type";
import { useGenerateRefreshToken } from "../../../../services/home/home-query";
import CommonPrimaryButton from "../../../../components/common/CommonPrimaryButton";
import { Box } from "@mui/material";
import * as paths from "../../../../constants/paths";

const Content = () => {
  const redirectUrl = getRedirectUrl();
  const { data: dataError } = useGetError();
  const [retryCount, setRetryCount] = useState(0);
  const refreshToken = getRefreshToken();
  const { navigate } = useRouter();
  const connectionLostErrorImg = getBase64Img();

  const {
    data: newToken,
    mutate: genNewToken,
    error: onSetTokenError,
  } = useGenerateRefreshToken();

  const error = useMemo(() => {
    resetToken();
    let error: Partial<ErrorRenderType> = {};
    if (dataError) {
      const { status, data } = dataError;
      const { exp, code, message } = data || {};
      if (status && status === 502) {
        error = {
          title: "Unable to send your response",
          detail: "Please try again later.",
          isErrorCode: false,
          errorCode: "9999999",
          errorMessage: "Connection Lost",
          isShowDetail: false,
        };
      } else if (code === undefined) {
        error = {
          title: "Your session has expired.",
          detail: "กรุณาลองใหม่อีกครั้ง",
          isErrorCode: false,
          errorCode: code || "9999999",
          errorMessage: "Service Timeout",
          isShowDetail: false,
        };
      } else {
        error = {
          title: "Unable to send your response",
          detail: "Please try again later.",
          isErrorCode: true,
          errorCode: code || "9999999",
          errorMessage: message || "Unknown Error",
          isShowDetail: true,
        };
      }
    }

    return error;
  }, [dataError]);

  const handleRetry = () => {
    genNewToken({
      refresh_token: refreshToken || "",
    });
    navigate(paths.home());
  };

  // useEffect(() => {
  //   setRetryCount(0);
  // }, [newToken]);

  // useEffect(() => {
  //   if (onSetTokenError) {
  //     setRetryCount((prev) => prev + 1);
  //   }
  // }, [onSetTokenError]);

  // useEffect(() => {
  //   if (retryCount >= 2) {
  //     error.isErrorCode = true;
  //   }
  // }, [retryCount]);

  const handleReturn = () => {
    window.location.href = redirectUrl || "";
  };

  return (
    <CardContainer>
      <StyledImage
        src={!error.isErrorCode ? timeoutErrorImg : genericErrorImg}
        alt={`${error?.title} ${error?.detail}`}
        draggable={false}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = connectionLostErrorImg || "";
        }}
      />
      <ContainerTitle>
        <Text size={"24px"} weight={600} lineHeight={"32px"} color="white">
          {error?.title}
        </Text>
      </ContainerTitle>
      {error?.isShowDetail && (
        <ContainerErrorCode>
          <Text weight={400} size={"12px"} lineHeight={"16px"} color="#CED2DB">
            {error?.detail}
          </Text>
          <Text weight={400} size={"12px"} lineHeight={"16px"} color="#CED2DB">
            Error Code: {error?.errorCode} - {error?.errorMessage}
          </Text>
        </ContainerErrorCode>
      )}
      <Box marginTop={"24px"}>
        <CommonPrimaryButton
          onClick={handleReturn}
          width="320px"
          height="50px"
          margin="0px 28px"
        >
          <Text
            color="#0E1013"
            type="Bold"
            size="16px"
            lineHeight="24px"
            weight={600}
          >
            {!!error.isErrorCode ? "Back to Survey list" : "Retry"}
          </Text>
        </CommonPrimaryButton>
      </Box>
    </CardContainer>
  );
};

export default Content;
