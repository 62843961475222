import { Box, TextField, Link, Container } from "@mui/material";
import { useLayout } from "../../context/LayoutContext";
import {
  containerStyle,
  inputBaseStyle,
  inputLabelStyle,
  outlineInputStyle,
  OptionalQuestionStyle,
  QuestionDescriptionText,
  inputLabelFocusErrorStyle,
  inputLabelFocusStyle,
  inputLabelErrorStyle,
  inputBaseErrorStyle,
  outlineInputErrorStyle,
  InlineRequireText,
  DangerousDivTiTle,
} from "../common/Style";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { QuestionImages } from "../common/Image";
import { TextFieldProps } from "./type";
import { getFieldLang } from "../../utils/helper";

function TextFieldQuestion({
  qid,
  questionTitle,
  required,
  questionDescription,
  hyperlink,
  setValueAnswer,
  stateAnswer,
  questionImage,
  register,
  errors,
  resetField,
  section,
  trigger,
  questionNo,
}: TextFieldProps) {
  const { setAnswerCount } = useLayout();
  const { ref, ...rest } = register(`${qid - 1}`, {
    required: required,
  });
  const [checkError, setCheckError] = useState(
    errors[qid! - 1] && errors[qid! - 1]?.type === "required"
  );
  const labelStyle = checkError ? inputLabelErrorStyle : inputLabelStyle;
  const baseStyle = checkError ? inputBaseErrorStyle : inputBaseStyle;
  const outlineStyle = checkError ? outlineInputErrorStyle : outlineInputStyle;
  const focusStyle = checkError
    ? inputLabelFocusErrorStyle
    : inputLabelFocusStyle;

  useEffect(() => {
    setCheckError(errors[qid! - 1] && errors[qid! - 1]?.type === "required");
  }, [errors[qid! - 1]]);

  const setAnswerCountTextField = (required: boolean, answer: string) => {
    if (required) {
      setAnswerCount((prev) => {
        const updatedCount = [...prev];
        if (answer.trim() !== "") {
          if (!updatedCount[section].includes(qid)) {
            updatedCount[section].push(qid);
          }
        } else {
          updatedCount[section] = updatedCount[section].filter(
            (id) => id !== qid
          );
        }
        return updatedCount;
      });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const answer = e.target.value.trim();
    setValueAnswer(`${qid - 1}`, {
      q_id: qid,
      opt_id: null,
      opt_ids: null,
      opt_short_text: answer,
      opt_long_text: null,
      opt_others: null,
    });
    setAnswerCountTextField(required, answer);
    if (answer.length === 0) {
      resetField(`${qid! - 1}`);
    }
    trigger?.(`${qid! - 1}`);
  };

  useMemo(() => {
    if (stateAnswer?.opt_short_text && stateAnswer?.opt_short_text.length > 0) {
      setAnswerCountTextField(required, stateAnswer?.opt_short_text);
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid!,
        opt_id: null,
        opt_ids: null,
        opt_short_text: stateAnswer?.opt_short_text,
        opt_long_text: null,
        opt_others: null,
      });
    }
  }, []);

  return (
    <Container sx={containerStyle} id={`${qid}`}>
      <Box sx={{ textAlign: "left" }} marginBottom={"8px"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DangerousDivTiTle
            dangerouslySetInnerHTML={{
              __html: `${questionNo}. ${questionTitle}`,
            }}
          />
          {!required && (
            <OptionalQuestionStyle> (optional)</OptionalQuestionStyle>
          )}
        </Box>
        <Box>
          <QuestionDescriptionText>
            {questionDescription}
          </QuestionDescriptionText>
          <Link href={hyperlink}>{hyperlink}</Link>
        </Box>
        {questionImage && <QuestionImages image={questionImage} />}
        <TextField
          {...rest}
          onChange={handleInputChange}
          placeholder={questionDescription}
          fullWidth
          InputProps={{ readOnly: false }}
          InputLabelProps={{
            style: labelStyle,
          }}
          sx={{
            "& .MuiInputBase-root": baseStyle,
            "& .MuiOutlinedInput-root": outlineStyle,
            "& .MuiInputLabel-root.Mui-focused": focusStyle,
          }}
          defaultValue={stateAnswer?.opt_short_text}
        />
        {errors[qid! - 1] && errors[qid! - 1]?.type === "required" && (
          <InlineRequireText>This is required</InlineRequireText>
        )}
      </Box>
    </Container>
  );
}

export default TextFieldQuestion;
