import { Button } from "@mui/material";
import styled from "styled-components";

export const CommonSecondaryButtonStyle = styled(Button)<{
  width?: string;
  height?: string;
  maxWidth?: string;
  margin?: string;
}>`
  &.MuiButtonBase-root {
    width: ${(props) => (props.width ? props.width : "160px")};
    height: ${(props) => (props.height ? props.height : "50px")};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
    margin: ${(props) => (props.margin ? props.margin : "")};
    color: #fff;
    background: rgba(255, 255, 255, 0.08);
    padding: 16px;
    border-radius: 999px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: none;
  }

  &.MuiButtonBase-root:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;
