import { Container, StyledImage } from "./style";
import finishImage from "../../../../constants/images/image_finish.svg";
import { Box, Typography } from "@mui/material";
import CommonPrimaryButton from "../../../../components/common/CommonPrimaryButton";
import Text from "../../../../components/common/Text";
import { getRedirectUrl } from "../../../../utils/helper";
import { useEffect, useState } from "react";
import Loading from "../../../../components/common/Loading";

const Content = () => {
  const redirectUrl = getRedirectUrl();
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return isLoading && imageLoaded ? (
    <Box sx={{ left: "50%", position: "absolute", top: "50%" }}>
      <Loading loading={isLoading} />
    </Box>
  ) : (
    <Container>
      <Box>
        <StyledImage
          onLoad={handleImageLoad}
          src={finishImage}
          alt="finishImage"
          draggable={false}
        />
      </Box>
      <Box sx={{ marginTop: "4px", color: "#fff" }}>
        <Typography
          sx={{
            lineHeight: "32px",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          Excellent!
        </Typography>
        <Typography
          sx={{
            marginTop: "8px",
            lineHeight: "24px",
            fontSize: "12px",
            fontWeight: 400,
          }}
        >
          Now, let's return to the Survey List to claim your Token and NFT.
        </Typography>
      </Box>
      {/* <Box sx={{ width: "320px", maxWidth:"320px"}}> */}
      <CommonPrimaryButton
        onClick={() => {
          window.location.href = redirectUrl || "";
        }}
        width="100%"
        maxWidth="320px"
        height="50px"
        margin="8px 28px 0px"
      >
        <Text
          color="#0E1013"
          type="Bold"
          size="16px"
          lineHeight="24px"
          weight={600}
        >
          Back to Survey list
        </Text>
      </CommonPrimaryButton>
      {/* </Box> */}
    </Container>
  );
};

export default Content;
