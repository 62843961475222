import { Box, Button, Container, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useLayout } from "../../context/LayoutContext";
import { Controller } from "react-hook-form";
import {
  containerStyle,
  OptionalQuestionStyle,
  QuestionTitleText,
  QuestionDescriptionText,
  InlineRequireText,
  DangerousDivTiTle,
} from "../common/Style";
import {
  ButtonBox,
  IndicatorBox,
  IndicatorText,
  InnerBox,
  OuterBox,
} from "./style";
import { QuestionImages } from "../common/Image";
import { RatingProps } from "./type";

const RatingScore = ({
  qid,
  required,
  imageRequired,
  questionTitle,
  description,
  questionImage,
  questionOption,
  ratingIndicators,
  section,
  setValueAnswer,
  stateAnswer,
  control,
  errors,
  trigger,
  questionNo,
}: RatingProps) => {
  const [rating, setRating] = useState(stateAnswer?.opt_id || null);
  const { setAnswerCount } = useLayout();

  let activeStyle = {
    background: "",
    border: "",
  };
  const setAnswerCountRating = (required: boolean) => {
    if (required) {
      setAnswerCount((prev) => {
        const updatedCount = [...prev];
        if (!updatedCount[section].includes(qid)) {
          updatedCount[section].push(qid);
        }
        return updatedCount;
      });
    }
  };

  useMemo(() => {
    if (stateAnswer?.opt_id) {
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid!,
        opt_id: stateAnswer?.opt_id || null,
        opt_ids: null,
        opt_short_text: null,
        opt_long_text: null,
        opt_others: null,
      });
      if (stateAnswer?.opt_id) {
        setAnswerCountRating(required);
      }
    }
  }, []);

  const onClickHandler = (r: number) => {
    setRating(r);
    setValueAnswer(`${qid! - 1}`, {
      q_id: qid!,
      opt_id: r,
      opt_ids: null,
      opt_short_text: null,
      opt_long_text: null,
      opt_others: null,
    });
    setAnswerCountRating(required);
    trigger(`${qid! - 1}`);
  };
  const activeQuestions = useMemo(() => {
    const dataQuestions = questionOption.filter(
      (options) => !options.deactivate
    );
    return dataQuestions;
  }, []);
  return (
    <>
      <Container sx={containerStyle} id={`${qid!}`}>
        <Box sx={{ padding: 0 }}>
          <Box sx={{ textAlign: "left" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <DangerousDivTiTle
                dangerouslySetInnerHTML={{
                  __html: `${questionNo}. ${questionTitle}`,
                }}
              />
              {!required && (
                <OptionalQuestionStyle> (optional)</OptionalQuestionStyle>
              )}
            </Box>
            <QuestionDescriptionText>{description}</QuestionDescriptionText>
            {questionImage && <QuestionImages image={questionImage} />}

            <OuterBox>
              <InnerBox>
                <ButtonBox>
                  <Controller
                    name={`${qid! - 1}`}
                    rules={{ required: required }}
                    control={control}
                    key={`${qid! - 1}-${Math.random()}`}
                    render={() => {
                      return (
                        <>
                          {activeQuestions!.map((item, index) => {
                            activeStyle.background =
                              rating === item.id
                                ? "rgba(26,229,188,0.08)"
                                : "rgba(255, 255, 255, 0.08)";
                            activeStyle.border =
                              rating === item.id
                                ? "2px solid #1AE5BC"
                                : "2px solid rgba(0, 0, 0, 0)";
                            return (
                              <Button
                                name={`${qid! - 1}`}
                                key={item.id}
                                sx={{
                                  border: activeStyle.border,
                                  backgroundColor: activeStyle.background,
                                  height: "56px",
                                  color: "#fff",
                                  fontWeight: "600",
                                  fontSize: "20px",
                                  borderRadius: "8px",
                                  lineHeight: "28px",
                                  width: "100%",
                                  minWidth: "0px",
                                  "@media (max-width: 375px)": {
                                    fontSize: "10px",
                                  },
                                  "@media (max-width: 525px)": {
                                    fontSize: "15px",
                                  },
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onClickHandler(item.id);
                                }}
                              >
                                {item!.label_en}
                              </Button>
                            );
                          })}
                        </>
                      );
                    }}
                  />
                </ButtonBox>
                <IndicatorBox>
                  {ratingIndicators?.map((item, index) => {
                    return (
                      <IndicatorText key={index}>{item.title_en}</IndicatorText>
                    );
                  })}
                </IndicatorBox>
                {errors[qid - 1] && errors[qid - 1]?.type === "required" && (
                  <InlineRequireText>This is required</InlineRequireText>
                )}
              </InnerBox>
            </OuterBox>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default RatingScore;
