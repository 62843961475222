import styled from "styled-components";
import { GRAY_F9FAFB } from "../../../../constants/colors";

export const Container = styled.div`
  background-color: ${GRAY_F9FAFB};
  display: flex;
  flex-direction: column;
  height: 100vh;

  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;

  // padding-top: 80px;
  text-align: center;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 409px;
  max-height: 213px;
  object-fit: fit;
  align-self: center;
`;

export const ContainerTitle = styled.div`
  margin-top: 16px;
`;

export const ContainerDetail = styled.div`
  margin-top: 16px;
`;

export const ContainerErrorCode = styled.div`
  margin-top: 16px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;
