import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Loading from "../../../../components/common/Loading";
import Landing from "../../../../components/Landing";
import OnePage from "../OnePage";
import Section from "../Section";
import { SurveyProps } from "./type";
import { SectionData } from "../Section/type";
import { useLayout } from "../../../../context/LayoutContext";
import {
  getFieldLang,
  getLang,
  getResponseQuestionaire,
} from "../../../../utils/helper";

const Survey = ({ data }: SurveyProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [submitConsent, setSubmitConsent] = useState(null);
  const consents = data?.consents;
  const landing = data?.landing;
  const questionList = data?.questions;
  const { isTabletSize } = useLayout();
  const isConsentAcknowledged = getResponseQuestionaire();
  useEffect(() => {
    if (consents && landing) {
      setIsLoading(false);
    }
  }, [consents, landing, setIsLoading]);

  const onSubmitConsent = (value: any) => {
    const payload = value.map((item: any) => {
      return {
        consent_id: item.consent_id,
        value: true,
      };
    });
    setSubmitConsent(payload);
  };

  useEffect(() => {
    if (!data.page_format || data.page_format === "") {
      navigate("/error");
    }
  }, [data.page_format]);

  const lang = useMemo(() => {
    return getLang() || "en";
  }, []);

  return isLoading ? (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Loading loading={isLoading} />
    </Box>
  ) : (
    <span>
      {!submitConsent && isConsentAcknowledged === "null" && (
        <Landing
          header={getFieldLang(landing, "header", lang)}
          consents={consents}
          content={getFieldLang(landing, "content", lang)}
          footer={getFieldLang(landing, "footer", lang)}
          icon={landing.icon}
          submit={onSubmitConsent}
        />
      )}
      {(submitConsent || isConsentAcknowledged !== "null") && (
        <div
          style={{
            display: "",
            textAlign: `center`,
            marginTop: isTabletSize ? "" : "32px",
          }}
        >
          {data.page_format === "single" ? (
            <OnePage data={data} />
          ) : (
            <Section data={data.questions} />
          )}
        </div>
      )}
    </span>
  );
};

export default Survey;
