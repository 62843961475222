import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface LayoutContextType {
  answerCount: number[][];
  setAnswerCount: React.Dispatch<React.SetStateAction<number[][]>>;
  isTabletSize: boolean;
}

const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType);

const LayoutProvider = ({ children }: { children: ReactNode }) => {
  //handle save state after retry
  const [answerCount, setAnswerCount] = useState<number[][]>([]);
  const [isTabletSize, setisTabletSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setisTabletSize(window.innerWidth <= 1190);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const contextValue = {
    answerCount,
    setAnswerCount,
    isTabletSize,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

const useLayout = () => {
  return useContext(LayoutContext);
};

export { LayoutProvider, useLayout };
