import styled from "styled-components";
import { Box, Card, CardActions, CardContent } from "@mui/material";

export const StyledCard = styled(Card)`
  &.MuiPaper-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    text-align: center;
    margin: 0px 129px 0px 127px;
    border-radius: 16px;
    max-width: 598px;
    height: 360px;
    background-color: transparent;
    overflow: visible;
    align-self: center;
    box-shadow: none;
    background: #1a1d23;
    position: relative;

    @media (max-width: 834px) {
      &.MuiPaper-root {
        margin: 0px 80px;
      }
    }
    @media (max-width: 550px) {
      &.MuiPaper-root {
        margin: 0px 32px;
      }
    }
    @media (max-width: 375px) {
      &.MuiPaper-root {
        margin: 0px 16px;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 80%;
    overflow-y: scroll;
    padding-left: 40px;
    padding-right: 40px;
  }
  ::-webkit-scrollbar {
    width: 4px; /* width of the scrollbar */
    height: 328px;
  }

  ::-webkit-scrollbar-track {
    background-color: #23262c; /* color of the track */
    border-radius: 999px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #585a5f; /* color of the thumb */
    border-radius: 999px;
  }
  margin-bottom: 16px;
  margin-right: 4px;
`;
export const StyledContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;
export const HeaderContainer = styled.div`
  font-size: 24px;
  line-height: normal;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  display: flex;
  background: linear-gradient(90deg, #52e0ab 30%, #0183e8 61.83%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 80px 40px;
`;
export const ContentContainer = styled.div`
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  color: #ced2db;
  max-width: 736;
  margin-top: 45px;
`;
export const StyledCardActions = styled(CardActions)`
  &.MuiCardActions-root {
    max-width: 992px;
    max-height: 158px;
    padding: 32px;
    flex-direction: column;
    gap: 24px;
    border-radius: 0px 0px 22px 22px;
    background: var(--foundation-blue-blue-500, #005ae6);
    width: -webkit-fill-available;
  }
`;
export const ContentFooter = styled.div`
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  color: #ced2db;
  align-items: center;
  font-style: normal;
  max-width: 736px;
`;

export const Icon = styled.img`
  width: 120px;
  height: 120px;
  position: relative;
  flex-shrink: 0;
`;

export const IconBox = styled(Box)`
  max-width: 12.5rem;
  max-height: 12.5rem;
  position: absolute;
  top: -55px;
`;
