import { Box, Container } from "@mui/material";
import {
  containerWidth,
  lgMediaQuery,
  mdMediaQuery,
  smMediaQuery,
} from "../Style";

interface imageProps {
  image?: string;
}

const SectionImage = ({ image }: imageProps) => {
  return (
    <Container sx={{ width: containerWidth, marginTop: "24px" }}>
      <Box
        sx={{
          width: "100%",
          height: "388px",
          borderRadius: "8px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          [smMediaQuery]: {
            maxWidth: "375px",
            width: "100vw",
            paddingX: "24px",
          },
          [mdMediaQuery]: {
            maxWidth: "834px",
            width: "100vw",
            paddingX: "24px",
          },
          [lgMediaQuery]: {
            width: "690px",
            maxWidth: containerWidth,
            paddingX: "24px",
          },
        }}
      >
        <img
          style={{
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            alignSelf: "center",
          }}
          alt="required"
          src={image}
        ></img>
      </Box>
    </Container>
  );
};

export default SectionImage;

export const QuestionImages = ({ image }: imageProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "386px",
        marginTop: "20px",
        borderRadius: "8px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        [smMediaQuery]: {
          maxWidth: "375px",
          width: "100vw",
          paddingX: "24px",
        },
        [mdMediaQuery]: {
          maxWidth: "834px",
          width: "100vw",
          paddingX: "24px",
        },
        [lgMediaQuery]: {
          width: "690px",
          maxWidth: containerWidth,
          paddingX: "24px",
        },
      }}
    >
      <img
        style={{
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          alignSelf: "center",
        }}
        alt="required"
        src={image}
      ></img>
    </Box>
  );
};
