import styled from "styled-components";
import { GRAY_F9FAFB } from "../../constants/colors";

export const Container = styled.div`
  background-color: ${GRAY_F9FAFB};

  height: 100vh;
  position: fixed;
  width: 100vw;
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 40px;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  padding-bottom: 98px;
`;
