import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const ButtonBox = styled(Box)`
  gap: 8px;
  display: flex;
  @media (max-width: 475px) {
    gap: 4px;
  }
`;

export const OuterBox = styled(Box)`
  display: flex;
  margin-top: 20px;
`;

export const InnerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const IndicatorBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const IndicatorText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    color: #caced7;
  }
`;

export default function ratingButtonStyle(
  borderActive,
  backgroundActive: boolean
) {
  return {
    border: borderActive
      ? "rgba(26,229,188,0.08)"
      : "rgba(255, 255, 255, 0.08)",
    backgroundColor: backgroundActive
      ? "2px solid #1AE5BC"
      : "rgba(255, 255, 255, 0.08)",
    height: "56px",
    color: "#fff",
    fontWeight: "600",
    fontSize: "20px",
    borderRadius: "8px",
    lineHeight: "28px",
    width: "100%",
    minWidth: "40px",
  };
}
